text.label.outside-bars {
  font-size: 10px !important;
  font-family: "IBM Plex Mono", monospace !important;
  font-weight: 400 !important;
  fill: black !important;
}
text.label {
  font-size: 11px !important;
  font-family: "IBM Plex Mono", monospace !important;
  font-weight: 400 !important;
  fill: white !important;
}

text.dateCounterText {
  fill: black !important;
}

text.valueLabel {
  font-size: 14px !important;
}

g.tick text {
  font-size: 14px !important;
}
