@import "mapbox-gl/dist/mapbox-gl.css";
@import "mapbox.css";
@import "loading.css";
@import "fonts.css";
@import "react-tooltip/dist/react-tooltip.css";
@import "scrollbar.css";
@import "mobile-landscape.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  #root {
    @apply h-full;
  }
  html,
  body {
    font-family: "IBM Plex Mono", monospace;
    @apply h-full;
    @apply select-none;
    @apply antialiased text-white;
    @apply overflow-hidden;
    @apply text-black;
  }
}
.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.annual-variation-toggle .react-switch-bg {
  border: 1px solid #2d3748;
  background: transparent !important;
  background-color: transparent !important;
  /* @apply bg-gradient-to-r from-black/30 to-white/30; */
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.3),
    rgba(255, 255, 255, 0.3)
  ) !important;
}
.annual-variation-toggle .react-switch-handle {
  border: 1px solid #2d3748 !important;
}

input:focus-visible {
  /* outline: 2px solid #ff3d00;
  outline-offset: 2px; */
  outline: none;
}

/* .html2canvas-container {
  visibility: unset !important;
  position: fixed !important;
  left: 0px !important;
  top: 0px !important;
  border-width: 0px !important;
  width: 90% !important;
  height: 90% !important;
  z-index: 999 !important;
} */
