:root {
  --current-scale: 1; /* Escala inicial por defecto */
}

body {
  transform: scale(var(--current-scale)) !important;
  transform-origin: top left !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  margin: 0 !important;
  padding: 0 !important;

  /* Cálculos dinámicos basados en la escala */
  width: calc(100vw / var(--current-scale)) !important;
  height: calc(100dvh / var(--current-scale)) !important;
  transform: scale(var(--current-scale)) !important;
}
.w-screen {
  width: calc(100vw / var(--current-scale)) !important;
}
.h-screen,
.h-dvh {
  height: calc(100dvh / var(--current-scale)) !important;
}

/* Pantallas Grandes (1920px) */
@media (orientation: landscape) and (max-width: 1920px) {
  :root {
    --current-scale: 1;
  }
}

/* Pantallas Medianas (1440px) */
@media (orientation: landscape) and (max-width: 1440px) {
  :root {
    --current-scale: 0.9;
  }
}
@media (orientation: landscape) and (max-width: 1280px) {
  :root {
    --current-scale: 0.8;
  }
}

/* Pantallas Pequeñas (1024px) */
@media (orientation: landscape) and (max-width: 1024px) {
  :root {
    --current-scale: 0.7;
  }
}

@media (orientation: landscape) and (max-width: 850px) {
  :root {
    --current-scale: 0.6;
  }
}

/* Pantallas Muy Pequeñas (768px) */
@media (orientation: landscape) and (max-width: 768px) {
  :root {
    --current-scale: 0.5;
  }
}

/* Pantallas Extra Pequeñas (640px) */
@media (orientation: landscape) and (max-width: 640px) {
  :root {
    --current-scale: 0.4;
  }
  body {
    display: none;
  }
  html {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: #000;
  }
  html::before {
    content: "No se puede visualizar la aplicación en pantallas tan pequeñas";
    display: block;
    font-size: 1em;
    color: white;
    text-align: center;
    width: 100%;
    padding: 1em;
  }
}

@media (orientation: portrait) and (max-width: 420px) {
  :root {
    --current-scale: 0.9;
  }
}
@media (orientation: portrait) and (max-width: 400px) {
  :root {
    --current-scale: 0.8;
  }
}
/* 
body {
  zoom: 1;
}
@media (orientation: landscape) and (max-width: 1920px) {
  body {
    transform: scale(0.9) !important;
    transform-origin: top left !important;
    width: 111.11vw !important;
    height: 111.11dvh !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .w-screen {
    width: 111.11vw !important;
  }
  .h-screen,
  .h-dvh {
    height: 111.11dvh !important;
  }
}
@media (orientation: landscape) and (max-width: 1440px) {
  body {
    transform: scale(0.7) !important;
    transform-origin: top left !important;
    width: 142.85vw !important;
    height: 142.85dvh !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .w-screen {
    width: 142.85vw !important;
  }
  .h-screen,
  .h-dvh {
    height: 142.85dvh !important;
  }
} */

/* @media (orientation: landscape) and (max-width: 1440px) {
  body {
    zoom: 0.9;
  }
}
@media (orientation: landscape) and (max-width: 1280px) {
  body {
    zoom: 0.8;
  }
  .text-xs {
    line-height: 1rem !important;
  }
  .sm\:text-sm {
    line-height: 1rem !important;
  }
}
@media (orientation: landscape) and (max-width: 1024px) {
  body {
    zoom: 0.7;
  }
  .text-xs {
    line-height: 0.9rem !important;
  }
  .sm\:text-sm {
    line-height: 0.9rem !important;
  }
}
@media (orientation: portrait) and (max-width: 850px) {
  body {
    zoom: 1;
  }
} */
/* @media (orientation: landscape) and (max-width: 1024px) { */
/* @media (orientation: landscape) and (max-width: 850px) { */
/* @media (orientation: landscape) and (max-width: 850px) {
  body {
    transform: scale(0.6) !important;
    transform-origin: top left !important;
    width: 166.66vw !important;
    height: 166.66dvh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .w-screen {
    width: 166.66vw !important;
  }

  .h-screen,
  .h-dvh {
    height: 166.66dvh !important;
  }
}

@media (orientation: landscape) and (max-width: 768px) {
  body {
    transform: scale(0.5) !important;
    width: 200vw !important;
    height: 200dvh !important;
    transform-origin: top left !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .w-screen {
    width: 200vw !important;
  }
  .h-screen,
  .h-dvh {
    height: 200dvh !important;
  }
} */

/* @media (orientation: landscape) and (max-width: 850px) {
  body {
    zoom: 0.6;
  }
}
@media (orientation: landscape) and (max-width: 768px) {
  body {
    zoom: 0.5;
  }
} */
/* @media (orientation: landscape) and (max-width: 640px) {
  html {
    zoom: 0.4;
  }
} */
/* @media (orientation: landscape) and (max-width: 1280px) { */
/* zoom: 0.75; */
/* @media (orientation: landscape) and (max-width: 1280px) {
  html {
    zoom: 0.6;
  }
}
@media (orientation: landscape) and (max-width: 1024px) {
  html {
    zoom: 0.5;
  }
} */
/* 



@media (orientation: portrait) {
  html {
    zoom: 1;
  }
} */

/* 
@media (max-width: 850px) and (orientation: landscape) and (max-height: 500px) {
  body {
    transform: scale(0.6) !important;
    transform-origin: top left !important;
    width: 166.66vw !important;
    height: 166.66dvh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .w-screen {
    width: 166.66vw !important;
  }

  .h-screen,
  .h-dvh {
    height: 166.66dvh !important;
  }
}

@media (max-width: 768px) and (orientation: landscape) and (max-height: 500px) {
  body {
    transform: scale(0.5) !important;
    width: 200vw !important;
    height: 200dvh !important;
    transform-origin: top left !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .w-screen {
    width: 200vw !important;
  }
  .h-screen,
  .h-dvh {
    height: 200dvh !important;
  }
} */
