.mapboxgl-ctrl-logo.mapboxgl-compact {
  opacity: 0;
}

.mapboxgl-ctrl-logo {
  opacity: 0;
}

.mapboxgl-ctrl-attrib {
  opacity: 0;
}

.mapboxgl-popup .mapboxgl-popup-tip {
  display: none;
}
.mapboxgl-popup .mapboxgl-popup-content {
  padding: 0;
}
.mapboxgl-popup .mapboxgl-popup-close-button {
  display: none;
}

/* .mapboxgl-popup {
    max-width: unset !important;
  }
  .mapboxgl-popup-content {
    background: #000000;
    border-radius: 0;
    box-shadow: 1px 7px 14px 4px rgb(0 0 0 / 35%);
  }
  .mapboxgl-popup-wrapper {
    z-index: 1;
  }
   */

.mapboxgl-popup {
  z-index: 9999;
}
.mapboxgl-popup-content {
  background: #000;
  border-radius: 0;
  border: 1px solid #fff;
  color: white;
}
.mapboxgl-popup {
  max-width: min(80vw, 300px) !important;
}

.mapboxgl-marker {
  z-index: 1;
}
