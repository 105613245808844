::-webkit-scrollbar {
  width: 5px; /* Más delgado */
  height: 5px; /* Para el scroll horizontal */
}

/* Estilo para el "thumb" del scrollbar (la parte que se desplaza) */
::-webkit-scrollbar-thumb {
  background-color: #000000; /* Color negro */
  border-radius: 0px; /* Rectangular */
}

/* Estilo para el fondo del scrollbar (pista) */
::-webkit-scrollbar-track {
  background-color: transparent; /* Fondo transparente */
  margin: 2px; /* Espacio entre el scrollbar y el borde */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
