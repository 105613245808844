/* styles.css */

/* Cambiar el cursor a pointer al pasar sobre el grupo 'g.node' */
.node {
  cursor: pointer;
}

/* Efecto de hover para los círculos */
.node circle {
  /* fill: white; */
  stroke-width: 0 !important;
  /* stroke: white !important; */
  /* stroke: gray; */
  /* stroke-width: 2px; */
  transition: fill 0.3s ease;
}
.node circle.disabled {
  fill: rgb(179, 179, 179) !important;
}
.node circle.disabled:hover {
  fill: rgb(179, 179, 179) !important;
}
.node text.disabled {
  fill: white !important;
}
.node-label-disabled {
  fill: white !important;
}
.node.back-button circle {
  fill: black;
  stroke: white !important;
  stroke-width: 1px !important;
}
.node.back-button circle:hover {
  fill: black;
  stroke: white !important;
  stroke-width: 1px !important;
}

/* Cambiar el color de relleno al hacer hover, excepto para el botón "Volver" */
.node:not(.back-button) circle:hover {
  /* fill: #e0e0e0 !important; */
  /* fill-opacity: 0.9; */
  fill: var(
    --hover-fill-color,
    #e0e0e0
  ); /* Usar la variable CSS para el relleno en hover */
  stroke: var(
    --hover-stroke-color
  ); /* Usar la variable CSS para el borde en hover */
}

/* Opcional: Transición para el texto */
.node text {
  transition: opacity 0.3s ease;
}
