@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.ibm-plex-mono {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
}
.ibm-plex-mono-thin {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-mono-extralight {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-mono-light {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-mono-regular {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-mono-medium {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-mono-semibold {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-mono-bold {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-mono-thin-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 100;
  font-style: italic;
}

.ibm-plex-mono-extralight-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-style: italic;
}

.ibm-plex-mono-light-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-style: italic;
}

.ibm-plex-mono-regular-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.ibm-plex-mono-medium-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  font-style: italic;
}

.ibm-plex-mono-semibold-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  font-style: italic;
}

.ibm-plex-mono-bold-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700;
  font-style: italic;
}
